.header{
    background-image: url('/public/pictures/SicHatPage-Headersmoll.png');
    background-position: center;
    height: 1100px;
    background-repeat: no-repeat;
}

.section1{
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 2%;
    padding-left: 5%;
    padding-right: 5%;
    margin-top: 150px;
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;
}

.section1 p{

}

.sh-para{
    width: 40%;
}

.sh-para2{
   
}

.music-link-row{
    display: flex;
    justify-content: space-around;
    margin-top: 150px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
}

.music-link-row a:hover{
    color: cyan;
}
.section2{
    background-image: url('/public/pictures/SicHatPage-Sec2.png');
    background-position: center top;
    background-repeat: no-repeat;
    height: fit-content;
    background-size: cover;
    margin-top: 120px;
    
}

.sec2--container{
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 800px;
    padding-left: 5%;
    padding-right: 5%;
    gap: 40%;
}

.sec2--container-box1{
    
}
.sec2--container {
  
}
.section3{
    max-width: 1500px;
    margin-left: auto;
    margin-right: auto;
}
.sec3--comtent{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15%;
    padding-left: 10%;
    padding-right: 5%;
    margin-top: 150px;
}

.sec3--comtent img{
    width: 30%;
    max-width: 250px;
}

.section4{
    padding-left: 10%;
    padding-right: 10%;
    margin-top: 150px;
    margin-bottom: 120px;
    display: flex;
    flex-flow:column;
    align-items: center;
    justify-content: center;
}
.skillrow{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    align-items: center;
}

a:hover{
    color: cyan;
}

@media only screen and (max-width: 850px){
    
    .header{
        background-size: cover;
        height: 400px;
        background-repeat: no-repeat;
        width: 100%;
    }

    .section1{
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        gap: 2%;
        padding-left: 0%;
        padding-right: 0%;
        margin-top: 50px;
        
    }

    .sh-para{
        width: 80%;
        text-align: justify;
    }

    iframe{
        width: auto;
    }

    .sec2--container{
        display: flex;
        flex-flow: column;
        justify-content: space-around;
        align-items: center;
        height: fit-content; 
        text-align: justify;
    }

    .sec3--comtent{
        display: flex;
        flex-flow: column;
        justify-content: center;
        align-items: center;
        gap: 15%;
        padding-left: 5%;
        padding-right: 5%;
        margin-top: 30px;
        text-align: justify;
    }

    .sec3--comtent img{
       
        visibility: hidden;
    }

    .music-link-row{
        
        margin-top: 50px;
        
    }
}