.drum-sec1{
    background-image: url('/public/pictures/40026367_2216968788333243_5846244629309030400_n.jpeg');
    background-size: cover;
    background-position: center;
    height: 800px;
    display: flex;
    justify-content: center;
}

.drum-sec1--content{
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.drum-sec1--content p{
    width: 25%;
    text-align: justify;
    margin-right: 12%;
    font-size: 1.25rem;
}

.drum-sec2{
    padding-left: 10%;
    padding-right: 10%;
    margin-top: 120px;
}


.drum-sec2-content{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5%;
}

.drum-sec2-content img{
    width: 50%;
}

.drum-sec3{
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    border-top: 1px solid rgb(27, 27, 27);
    border-bottom: 1px solid rgb(27, 27, 27);
    padding-bottom: 20px;
}

.drum-sec3 button{
    padding: 10px;
    box-sizing: content-box;
    font-weight: bold;
    cursor: pointer;
   
}

.drum-sec5{
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    margin-top: 150px;
    padding-left: 10%;
    padding-right: 10%;
    gap: 5%;
}

.drum-sec5 img{
    width: 30%;
}

.drum-sec6{
    background-image: url('/public/pictures/DrumPic3.jpg');
    background-size: cover;
    background-position: center;
    height: 600px;
}

.drum-sec6-p{
    height: 100%;
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 10%;
    padding-right: 10%;
    background-color: rgb(0, 0, 0, 0.5);
    margin-top: 150px;
}

.drum-sec6-p p{

    width: 40%;
}

#drumPic4{
    width: 50%;
}

.drum-sec7{
    background-image: url('/public/pictures/DrumPic5.jpg');
    background-position: center;
    background-size: cover;
    display: flex;
    justify-content: space-between;
    height: 600px;
    margin-top: 150px;
}

.drum-sec7-p{
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 10%;
    padding-right: 10%;
    background-color: rgb(0, 0, 0, 0.7);
    gap: 20%
}

@media only screen and (max-width: 600px){
    .drum-sec1{
        height: 400px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .drum-sec1--content{
        display: flex;
        justify-content: center;
        
    }

    .drum-sec1--content p{
        width: 85%;
        font-size: 0.8rem;
        margin-right: 0%;
        margin-top: 50%;
    }

    .drum-sec2{
        margin-top: 50px;
    }

    .drum-sec2-content{
        text-align: justify;
        flex-flow: column;

    }

    .drum-sec2-content img{
        margin-top: 10%;
        width: 100%;
    }

    .drum-sec5{
        flex-flow: column;
        text-align: justify;
        margin-top: 50px;
      
    }
    
    .drum-sec5 img{
        width: 100%;
    }

    .drum-sec6{
        background-size: cover;
        background-position: center;
        height: 600px;
    }
    
    .drum-sec6-p{
        height: fit-content;
        align-items: center;
        margin-top: 50px;
    }

    .drum-sec6-p p{
        text-align: justify;
        width: 100%;
    }

    #drumPic4{
        width: 100%;
    }

    .drum-sec7{ 
        height: fit-content;
        margin-top: 50px;
    }
    
    .drum-sec7-p{
        flex-flow: column;
        text-align: justify;
    }
}