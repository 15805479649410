.iconbox{
    display: flex;
    flex-flow: column;
    align-items: center;
    
}

.pic{
    background: linear-gradient(45deg, rgb(255, 88, 116), blueviolet);
    padding: 12px;
    border-radius: 50%;
    position: absolute;
    margin-top: 8px;
}

.textbox {
    width: 120px;
    font-size: 0.8rem;
    background-color: rgb(58, 58, 58);
    border: 0.5px solid grey;
    padding: 12px;
    text-align: center;
    box-shadow: 4px 4px  5px rgb(0, 0, 0);
}

.textbox:hover{
    background-color: rgb(94, 93, 93);
    
}


.textbox p {
    margin-top: 50px;
    line-height: 20px;
    text-align: center;
}

@media only screen and (max-width: 600px){
    .textbox{
        width: auto;
    }
}