.contact{
    background-image: url('/public/pictures/ContactPageBG.png');
    background-size: cover;
    background-repeat: no-repeat;
    height: fit-content;
}

.contact-info{
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    height: 600px;
    background-color: rgba(15, 15, 15, 0.2);
}

.link-list{
    display: flex;
    gap: 20px;
}