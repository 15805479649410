.skillcard{
    display: flex;
    flex-flow: column;
    background-color: rgb(58, 58, 58);
    border: 0.5px solid rgb(138, 138, 138);
    width: fit-content;
    min-width: 40px;
    justify-content: center;
    align-items: center;
    padding: 10px;
    box-shadow: 2px 2px 10px rgb(0, 0, 0);
   

}
