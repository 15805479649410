.footer--container{
    background-color: black;
    border-top: 1px solid rgb(43, 43, 43);
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 50px;
}

.footer-content{
    display: flex;
    justify-content: space-around;
    width: 100%;
    font-family: 'Oswald', sans-serif;
}

.quick-links{
    display: flex;
    flex-flow: column;
    align-self: flex-end;
}

.quick-links a:hover{
    color: rgb(0, 255, 255);
}

.link-list a:hover{
    color: rgb(0, 255, 255);
}