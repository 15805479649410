.teacher-container{
    background-image: url('/public/pictures/TeacherPic.png');
    background-repeat: no-repeat;
    background-size: cover;
    height: fit-content;
    display: flex;
    flex-flow: column;
}

.teacher-content{
    display: flex;
    justify-content: flex-start;
    flex-flow: column;
    padding-left: 10%;
    margin-top: 12%;

}

.teacher-content p{
    width: 40%;
}

.teach-sec1{
    display: flex;
    align-items: center;
    padding-left: 10%;
    padding-right: 10%;
    margin-top: 150px;
    gap: 10%;
}

.teach-sec1 img{
    width: 50%;
}

.teach-sec2{
    background-image: url('/public/pictures/EngineWide.png');
    background-size: cover;
    background-position: center;
    height: 800px;
    margin-top: 150px;
}

.teach-sec2-content{
    height: 100%;
    padding-left: 10%;
    padding-right: 10%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
}

.teach-sec2-p{
    margin-left: 50%;
    padding-bottom: 20%;
}

.teach-sec3-cont{
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 10%;
    padding-right: 10%;
    margin-top: 150px;
    gap: 10%;
    margin-bottom: 150px;
}

@media only screen and (max-width: 600px){
    
    .teacher-content{
        justify-content:center;
        text-align: justify;
        padding-right: 10%;
    }
    
    .teacher-content p{
        width: 100%;
    }

    .teach-sec1{
        flex-flow: column;
        text-align: justify;
        margin-top: 50px;
    }
    
    .teach-sec1 img{
        width: 100%;
    }

    .teach-sec2{
        height: fit-content;
        margin-top: 50px;
    }
    
    .teach-sec2-content{
 
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
    }
    
    .teach-sec2-p{
        margin-left: 0%;
        padding-bottom: 0%;
        text-align: justify;
    }

    .teach-sec3-cont{
        flex-flow: column;
        margin-top: 50px;
        gap: 10%;
        margin-bottom: 50px;
        text-align: justify;
    }
}