.nav--container{
    display: flex;
    position: fixed;
    width: 100%;
}

.nav--container-scroll{
    display: flex;
    position: fixed;
    background-color: black;
    width: 100%;
}

.nav--list{
    display: flex;
    list-style-type: none;
    align-items: flex-start;
    gap: 25px;
    font-size: 1.2rem;
}

.nav--list li:hover{
    text-decoration: underline;
}

@media only screen and (max-width: 600px){
   
    ul{
        margin-left: auto;
        margin-right: auto;
        padding: 0%;
    }
    

    .nanlink{
        padding: 0%;
        font-size: 1rem;
        margin: 0;
    }
}