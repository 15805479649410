.home--container{
    background-image: url('/public/pictures/HomePagePic.png');
    background-size: cover;
    background-position: right top;
    min-height: fit-content;

}

.home--content{
    display: flex;
    flex-flow: column;
    padding-top: 20%;
    padding-left: 5%;
}

.home--underline{
    display: flex;
    align-items: center;
    gap: 20px
}

#home-header{
    font-size: 3rem;
}

button{
    background-color: rgb(41, 41, 41);
    border: 0.5px solid grey;
    box-shadow: 2px 2px 10px black;
    color: white;
    padding: 10px 27px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-family: 'Oswald', sans-serif;
    font-size: 0.8rem;
    letter-spacing: 2px;
}

button:hover{
    background-color: gray;
}

#contactbutton{
   
}


h1{
    margin: 0;
}

a{
    color: white;
    text-decoration: none;
}

#ibox:hover{
    color: white;
}
.iconrow{
    display: flex;
    gap: 35px;
    margin-left: 5%;
    margin-top: 250px;
    padding-bottom: 30px;
}

@media only screen and (max-width: 600px) {
    .home--container{
        background-position: right bottom;
        height: 600px;

    }
    .home--content{
        display: flex;
        flex-flow: column;
        align-items: center;
        padding-top: 60%;
        margin-left: auto;
        margin-right: auto;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.3)
    }

    .home--underline{
        display: flex;
        flex-flow: column;
        align-items: center;
        justify-content: center;
        gap: 20px
    }

    #contactbutton{
        visibility: hidden;
    }

    #home-header{
        font-size: 1.5rem;
    }

    .iconrow{
        visibility: hidden;
    }
}