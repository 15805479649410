h1{
    font-family: 'Bebas Neue', cursive;
    letter-spacing: 0.4rem;
}

h2, h3{
    font-family: 'Oswald', sans-serif;
    font-weight: 1000;
}

p{
    font-family: 'Akshar', sans-serif;
    line-height: 2rem;
    font-weight: 1000;
    text-align: justify;
}