.worldrecord-container{
    background-image: url('/public/pictures/WTB_Header.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    height: 600px;
}

.wr-header{
    display: flex;
    flex-flow: column;
    justify-content: center;  
    align-items: center;
    height: 600px;
    font-size: 1.5rem;
    line-height: 50%;
    background-color: rgba(15, 15, 15, 0.6);
}

.wr-section2{
    display: flex;
    padding-left: 10%;
    padding-right: 10%;
    margin-top: 150px;
    gap: 10%;
    align-items: center;
}

.wr-section2 img{
    width: 40%;
}

.wr-section3{
    background-image: url('/public/pictures/WTB_Band.jpg');
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 450px;
    margin-top: 150px;
    display: flex;
    align-items: center;
}

.wr-video{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    background-color: rgba(15, 15, 15, 0.5);
}

.wr-video a:hover{
    color: cyan;
}
.wr-section4{
    display: flex;
    align-items: center;
    padding-left: 10%;
    padding-right: 10%;
    margin-top: 150px;
    gap: 10%
}

.wr-section4 img{
   width: 50%;
}

.wr-section5{
    background-image: url('/public/pictures/WtbPic3.jpg');
    background-position: center;
    background-size: cover;
    height: 600px;
}

.wr-section5-p{
    display: flex;
    align-items: center;
    height: 100%;
    padding-left: 10%;
    padding-right: 10%;
    margin-top: 150px;
    gap: 10%;
    background-color: rgba(15, 15, 15, 0.7);
}

.wr-section6{
    display: flex;
    align-items: center;
    padding-left: 10%;
    padding-right: 10%;
    margin-top: 150px;
    margin-bottom: 120px;
    gap: 10%;
}

.wr-section6 img{
    width: 50%;
 }

 #wtb{
    font-family: 'Cinzel', serif; 
    font-weight: 1000;
    font-size: 3rem;
 }

 @media only screen and (max-width: 600px){

    .wr-header{
        font-size: 1rem;
    }

    .wr-section2{
        display: flex;
        flex-flow: column;
        padding-left: 10%;
        padding-right: 10%;
        margin-top: 50px;
        gap: 10%;
        text-align: justify;
        align-items: center;
    }
    
    .wr-section2 img{
        width: 100%;
    }

    .wr-section3{
        background-size: contain;
        margin-top: 0;
    }
    
    .wr-video{
        flex-flow: column;
        visibility: hidden;
    }

    .wr-section4{
        flex-flow: column;
        text-align: justify;
        margin-top: 50px;
        gap: 10%
    }
    
    .wr-section4 img{
       width: 100%;
    }

    .wr-section5{
        height: fit-content;
    }
    
    .wr-section5-p{
        flex-flow: column;
        text-align: justify;
        margin-top: 50px;
      
    }

    .wr-section6{
        flex-flow: column;
        text-align: justify;
        margin-top: 50px;
      
    }
    
    .wr-section6 img{
        width: 100%;
     }
 }